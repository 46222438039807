import React from 'react';
import { NavLink } from "react-router-dom";

import './Footer.scss';

const Footer = () => {
  return (
    <div className="Footer">
      <div className="row">
        <div>
          <NavLink to="/">Home</NavLink>
        </div>
        |
        <div>
          <NavLink to="/takeout">Takeout Counter</NavLink>
        </div>
        |
        <div>
          <a href="https://twitter.com/OnchainIceCream" target="_blank" rel="noreferrer">Twitter</a>
        </div>
      </div>
      <div className="row" style={{fontSize: '80%'}}>
        Built with love for frozen treats by <a href="https://twitter.com/MikeSylphDapps" target="_blank" rel="noreferrer">mike.sylphdapps.eth</a>
      </div>
    </div>
  );
};

export default Footer;