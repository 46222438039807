const LOCALHOST_NETWORK_ID = 31337;
const BASE_GOERLI_NETWORK_ID = 84531;
const BASE_NETWORK_ID = 8453;

const NETWORKS = {
  [LOCALHOST_NETWORK_ID]: {
    NETWORK_NAME: "Localhost",
    NETWORK_ID: LOCALHOST_NETWORK_ID,
    STORAGE_ADDRESS: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
    MINTER_ADDRESS: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
  },
  [BASE_GOERLI_NETWORK_ID]: {
    NETWORK_NAME: "Base Goerli",
    NETWORK_ID: BASE_GOERLI_NETWORK_ID,
    STORAGE_ADDRESS: "0x57da1ed69e97a35c163d262f6bb0fd109d4affa2",
    MINTER_ADDRESS: "0x8d8d8bc062cbac57c2d9d4d6fe346138d9d518f5",
  },
  [BASE_NETWORK_ID]: {
    NETWORK_NAME: "Base",
    NETWORK_ID: BASE_NETWORK_ID,
    STORAGE_ADDRESS: "0xB18A12b3B64cB374DE0fe51a41478Aa13F2DE5E3",
    MINTER_ADDRESS: "0x9EDe365aCF1d835Fa5976299E94f1D463b4E113C",
  }
};
export const NETWORK = NETWORKS[BASE_NETWORK_ID];
