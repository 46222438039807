import Flavors from './Flavors';

export const FLAVOR_METADATA = [
  {id: Flavors.SURPRISE_ME, label: "Surprise me!"},
  {id: Flavors.CHOCOLATE, label: "Chocolate"},
  {id: Flavors.VANILLA, label: "Vanilla"},
  {id: Flavors.STRAWBERRY, label: "Strawberry"},
  {id: Flavors.MINT_CHIP, label: "Mint chip"},
  {id: Flavors.ORANGE_DREAMSICLE, label: "Orange dreamsicle"},
  {id: Flavors.CHOCOLATE_CHIP, label: "Chocolate chip"},
  {id: Flavors.BUTTER_PECAN, label: "Butter pecan"},
  {id: Flavors.PISTACHIO, label: "Pistachio"},
  {id: Flavors.BLUE_RASPBERRY, label: "Blue raspberry"}, 
  {id: Flavors.CHERRY, label: "Cherry"},
  {id: Flavors.COFFEE, label: "Coffee"},
  {id: Flavors.NEAPOLITAN, label: "Neapolitan"},
  {id: Flavors.RAINBOW_SHERBET, label: "Rainbow sherbet"},
];