import { FLAVOR_METADATA } from "../../constants/FlavorMetadata";

const FlavorSelector = ({scoop, onChange}) => {
  return (
    <div className='FlavorSelector'>
      <select
        onChange={e => onChange(e.target.value)}
        value={scoop ? scoop.flavor : ''}
      >
        <option
          value=""
          disabled
        >
          Select a flavor
        </option>
        {FLAVOR_METADATA.map(flavor => (
          <option
            key={flavor.id}
            value={flavor.id}
          >
            {flavor.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FlavorSelector;