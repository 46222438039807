import React, { useRef, useEffect, useState } from 'react';
import { ethers } from "ethers";
import { isFirefox } from "@braintree/browser-detection";
import OnchainIceCreamStorageABI from '../../abis/OnchainIceCreamStorage.json';
import { NETWORK } from '../../constants/Networks';
import {
  Awning,
  Footer,
  Sign,
} from '../../components';
import decodeDataUri from '../../utils/decodeDataUri';

import "./TakeoutCounter.scss";

const TAKEOUT_SERVER = "https://onchainicecream.shop";
//const TAKEOUT_SERVER = "http://localhost:3001";

// Modes
const UNSET = "UNSET";
const PNG = "PNG";
const SVG = "SVG";

const providerApiKey = "oKxs-03sij-U_N0iOlrSsZFr29-IqbuF";
const provider = new ethers.providers.JsonRpcProvider(`https://base-mainnet.g.alchemy.com/v2/${providerApiKey}`);
const storage = new ethers.Contract(NETWORK.STORAGE_ADDRESS, OnchainIceCreamStorageABI.abi, provider);

function TakeoutCounter() {
  const canvasRef = useRef(null);
  const [mode, setMode] = useState(UNSET);
  const [mouseIsOverPngButton, setMouseIsOverPngButton] = useState(false);
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(300);
  const [padding, setPadding] = useState(0);
  const [tokenId, setTokenId] = useState(1);
  const [svgString, setSvgString] = useState(null);
  const [maxTokenId, setMaxTokenId] = useState(null);
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    const load = async () => {
      const totalSupply = await storage.totalSupply();
      setMaxTokenId(totalSupply.toNumber());
    };
    load();
  }, []);

  const handleTokenChange = (event) => {
    setTokenId(event.target.value);
  };

  const handleDownloadSVGClick = async () => {
    const tokenNumber = parseInt(tokenId, 10);
    if (isNaN(tokenNumber) || tokenNumber < 1 || tokenNumber > maxTokenId) {
      alert(`Please enter a valid Onchain Ice Cream token ID (1-${maxTokenId})`);
      return; 
    }
    let uri = await storage.tokenURI(tokenId);
    uri = decodeDataUri(uri);
    uri = JSON.parse(uri);
    const svgString = decodeDataUri(uri.image);
    
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `onchain-ice-cream-${tokenId}.svg`; // Specify the desired file name
    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(url);
  }

  const handleLoadClick = async () => {
    const tokenNumber = parseInt(tokenId, 10);
    if (isNaN(tokenNumber) || tokenNumber < 1 || tokenNumber > maxTokenId) {
      alert(`Please enter a valid Onchain Ice Cream token ID (1-${maxTokenId})`);
      return; 
    }
    let uri = await storage.tokenURI(tokenId);
    uri = decodeDataUri(uri);
    uri = JSON.parse(uri);
    const svg = decodeDataUri(uri.image);
    setSvgString(svg);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if(!canvas) {
      return;
    }

    const img = new Image();
    img.onload = () => {
      const newDiv = document.createElement("div");
      newDiv.innerHTML = svgString;
      const rect = newDiv.querySelectorAll("svg > rect")[0];
      const fill = rect.getAttribute("fill");

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = fill;
      ctx.fillRect(0, 0, width, height);

      const shortestDimension = width < height ? width : height;
      const pxPadding = (shortestDimension / 2) * (padding / 100);
      const svgWidth = shortestDimension - (pxPadding * 2);
      const svgHeight = svgWidth;
      const svgX = (width - svgWidth) / 2;
      const svgY = (height - svgHeight) / 2;
      ctx.drawImage(img, svgX, svgY, svgWidth, svgHeight);

      setImgSrc(canvasRef.current.toDataURL());
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgString)}`;
  }, [mode, width, height, padding, svgString]);

  const handleSliderChange = (event) => {
    const { name, value } = event.target;
    if (name === 'width') {
      setWidth(parseInt(value, 10));
    } else if (name === 'height') {
      setHeight(parseInt(value, 10));
    } else if (name === 'padding') {
      setPadding(parseInt(value, 10));
    }
  };

  const download = async () => {
    const dataUri = canvasRef.current.toDataURL();
    try {
      const response = await fetch(`${TAKEOUT_SERVER}/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `dataUri=${encodeURIComponent(dataUri)}`,
      });
  
      if (response.ok) {
        const data = await response.json();
        const hash = data.hash;
  
        // Use the hash to retrieve the image URL
        const imageUrl = `${TAKEOUT_SERVER}/download/${hash}?id=${tokenId}`;
        console.log(imageUrl)
  
        // Open a new window/tab with the image URL
        window.open(imageUrl, '_blank');
      } else {
        console.error('Failed to save data as an image.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  console.log(isFirefox());

  return (
    <div className="TakeoutCounter">
      <Awning />
      <div className="foreground">
        <Sign />
        <hr />
        <div className="container">
          <div>Pick up offchain versions of your Onchain Ice Cream here.</div>
          <div className="controls">
            <div className="mode-controls">
              <button
                onClick={() => setMode(SVG)}
                className={mode === SVG ? 'primary' : 'secondary'}
              >I want an SVG</button>
              <button
                onClick={() => setMode(PNG)}
                className={mode === PNG ? 'primary' : 'secondary'}
                disabled={isFirefox()}
                onMouseOver={() => setMouseIsOverPngButton(true)}
                onMouseOut={() => setMouseIsOverPngButton(false)}
              >{
                isFirefox() && mouseIsOverPngButton
                ? 'Not supported in Firefox'
                : 'I want a PNG'
              }</button>
            </div>
            { mode !== UNSET &&
              <div className="token-id-input">
                <label htmlFor="token">Token ID:</label>
                <input
                  type="number"
                  id="token"
                  name="token"
                  min={1}
                  max={maxTokenId}
                  value={tokenId}
                  onChange={handleTokenChange}
                />
                <button
                  onClick={handleLoadClick}
                  className="primary"
                >Load</button>
              </div>
            }
          </div>
          { mode === SVG && svgString &&
            <React.Fragment>
              <div className="svg-container" dangerouslySetInnerHTML={{__html: svgString}} />
              <button
                onClick={handleDownloadSVGClick}
                className="primary"
              >Download SVG</button>
            </React.Fragment>
          }
          { mode === PNG && svgString &&
            <React.Fragment>
              <div>Adjust the width, height, and padding to your liking and then right click save your ice cream.</div>
              <div className="png-controls">
                <div className="control">
                  <div className="row">
                    <label htmlFor="width">Width:</label>
                    <input
                      type="number"
                      id="widthInput"
                      name="width"
                      value={width}
                      min={0}
                      max={2000}
                      onChange={handleSliderChange}
                    />
                    <div className="units">px</div>
                  </div>
                  <input
                    type="range"
                    id="width"
                    name="width"
                    min="0"
                    max="2000"
                    value={width}
                    onChange={handleSliderChange}
                  />
                </div>
                <div className="control">
                  <div className="row">
                    <label htmlFor="height">Height:</label>
                    <input
                      type="number"
                      id="heightInput"
                      name="height"
                      value={height}
                      min={0}
                      max={2000}
                      onChange={handleSliderChange}
                    />
                    <div className="units">px</div>
                  </div>
                  <input
                    type="range"
                    id="height"
                    name="height"
                    min="0"
                    max="2000"
                    value={height}
                    onChange={handleSliderChange}
                  />
                </div>
                <div className="control">
                  <div className="row">
                    <label htmlFor="padding">Padding:</label>
                    <input
                      type="number"
                      id="paddingInput"
                      name="padding"
                      value={padding}
                      min={0}
                      max={1000}
                      onChange={handleSliderChange}
                    />
                    <div className="units">%</div>
                  </div>
                  <input
                    type="range"
                    id="padding"
                    name="padding"
                    min="0"
                    max="100"
                    value={padding}
                    onChange={handleSliderChange}
                  />
                </div>
              </div>
              <div className="canvas-container">
                <canvas ref={canvasRef} style={{'display': 'none'}} />
                <img src={imgSrc} alt="" />
              </div>
              <button
                className="primary"
                onClick={download}
              >Download PNG</button>
            </React.Fragment>
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TakeoutCounter;
