import React from 'react';
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import {
  HomePage,
  TakeoutCounter,
} from './components';

import './App.scss';

const router = createBrowserRouter([
  { path: "*", Component: Root },
]);

function Root() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path="/takeout" element={<TakeoutCounter/>} />
      </Routes>
    </div>
  );
};

function App() {
  return <RouterProvider router={router} />;
}

export default App;
