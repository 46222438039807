const Flavors = {
  NONE: 0,
  CHOCOLATE: 1,
  VANILLA: 2,
  STRAWBERRY: 3,
  MINT_CHIP: 4,
  ORANGE_DREAMSICLE: 5,
  CHOCOLATE_CHIP: 6,
  BUTTER_PECAN: 7,
  PISTACHIO: 8,
  BLUE_RASPBERRY: 9,
  CHERRY: 10,
  COFFEE: 11,
  NEAPOLITAN: 12,
  RAINBOW_SHERBET: 13,
  SURPRISE_ME: 99,
};

module.exports = Flavors;