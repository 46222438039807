import React from 'react';
import { Link } from "react-router-dom";

import './Sign.scss';

const Sign = () => {
  return (
    <div className="Sign">
      <Link to="/">
        <div className="line-1">
          <img className="mom-img" src="./mom.png" alt=""/>
          <span className="text">
            <span className="mom">Mom</span>&nbsp;
            <span className="and">&amp;</span>&nbsp;
            <span className="pop">Pop's</span><br/>
          </span>
          <img className="pop-img" src="./pop.png" alt=""/>
        </div>
        <div className="line-2">
          <span className="text">
            <span className="onchain-ice-cream-shop">Onchain Ice Cream Shop</span>
          </span>
        </div>
      </Link>
    </div>
  );
}

export default Sign;