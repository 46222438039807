import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useDisconnect,
} from '@web3modal/ethers5/react'
import OnchainIceCreamMinterABI from '../../abis/OnchainIceCreamMinter.json';
import OnchainIceCreamStorageABI from '../../abis/OnchainIceCreamStorage.json';
import ConeTypes from '../../constants/ConeTypes';
import Flavors from '../../constants/Flavors';
import { FLAVOR_METADATA } from '../../constants/FlavorMetadata'
import Toppings from '../../constants/Toppings';
import { FEE_PER_SCOOP } from '../../constants/Fees';
import { NETWORK } from '../../constants/Networks';
import {
  Awning,
  FlavorSelector,
  Footer,
  Sign,
} from '../../components';
import decodeDataUri from '../../utils/decodeDataUri';
import shortenAddress from '../../utils/shortenAddress';

import './HomePage.scss';

const MENU = 0;
const ORDERING = 1;
const IMAGE = 2;

let ethereum;

createWeb3Modal({
  ethersConfig: defaultConfig({
    name: "Mom and Pop's Onchain Ice Cream Shop",
    description: 'Ice cream but onchain',
    url: 'https://onchainicecream.com', // origin must match your domain & subdomain
    icons: ['https://avatars.mywebsite.com/']
  }),
  chains: [{
    chainId: 8453,
    name: 'Base',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://mainnet.base.org'
  }],
  projectId: 'ce0dbf03dd757d00d278f8bf6ec4afd0',
  enableAnalytics: true // Optional - defaults to your Cloud configuration
});

const HomePage = () => {
  const [signer, setSigner] = useState(null);
  const [signerAddress, setSignerAddress] = useState(null);
  
  const [currPage, setCurrPage] = useState(MENU);
  const [numScoops, setNumScoops] = useState(null);
  const [scoops, setScoops] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const [minting, setMinting] = useState(false);
  const [svg, setSvg] = useState(null);
  const [numMinted, setNumMinted] = useState(null);
  const [tokenId, setTokenId] = useState(null);

  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect()
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  useEffect(() => {
    async function setWalletFields() {
      if(walletProvider) {
        const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
        const signer = await ethersProvider.getSigner()
        const signerAddress = await signer.getAddress();
        setSigner(signer);
        setSignerAddress(signerAddress);
      }
    }
    setWalletFields();
  }, [address, walletProvider]);

  const connectWallet = async () => {
    open();
  };

  const disconnectWallet = () => {
    setNumScoops(null);
    setScoops([]);
    setSigner(null);
    setSignerAddress(null);
    setSvg(null);
    setMinting(false);
    setCurrPage(MENU);
    disconnect();
  };

  const selectNumScoops = num => {
    if(scoops.length > num) {
      const newScoops = scoops.slice(0, num);
      setScoops(newScoops);
    }
    setNumScoops(num);
  }

  const selectFlavor = (flavor, i) => {
    const newScoops = [...scoops];
    newScoops[i] = {
      flavor: parseInt(flavor),
      topping: 0,
    };
    setScoops(newScoops);
  }
  
  const handleQuantitySelectorChange = e => {
    const v = parseInt(e.target.value);
    setQuantity(v);
  };

  const mint = async () => {
    if (scoops.length > 0) {
      if(quantity < 1 || quantity > 20 || isNaN(quantity)) {
        alert("Please enter a quantity between 1 and 20");
        return;
      }

      const coneType = ConeTypes.WAFFLE;
      const flavors = scoops.map(scoop => scoop.flavor);
      while(flavors.length < 3) {
        flavors.push(Flavors.NONE);
      }
      const toppings = [Toppings.NONE, Toppings.NONE, Toppings.NONE];

      const minter = new ethers.Contract(NETWORK.MINTER_ADDRESS, OnchainIceCreamMinterABI.abi, signer);
      const storage = new ethers.Contract(NETWORK.STORAGE_ADDRESS, OnchainIceCreamStorageABI.abi, signer);

      const getTokenImage = async tokenId => {
        let uri = await storage.tokenURI(tokenId);
        uri = decodeDataUri(uri);
        uri = JSON.parse(uri);
        const svg = decodeDataUri(uri.image);
        return svg;
      }

      setSvg(null);
      setNumMinted(0);
      setTokenId(null);

      try {
        const mintTx = await minter.mint(
          signerAddress,
          quantity,
          coneType,
          flavors,
          toppings,
          { value: FEE_PER_SCOOP.mul(scoops.length * quantity) }
        );
        setMinting(true);
        const receipt = await mintTx.wait();
        setMinting(false);
        setCurrPage(IMAGE);
        setNumMinted(quantity);

        const iface = new ethers.utils.Interface(OnchainIceCreamStorageABI.abi);
        const logs = receipt.logs.map(log => iface.parseLog(log));
        const tokenId = logs[0].args[2].toNumber();
        setTokenId(tokenId);

        const svg = await getTokenImage(tokenId);
        setSvg(svg);
      } catch (error) {
        console.error("Error minting:", error);
        setMinting(false);
      }
    }
  };

  const totalPrice = quantity ? FEE_PER_SCOOP.mul(scoops.length * quantity) : null;
  let usd = 0;
  if(totalPrice) {
    usd = parseFloat(ethers.utils.formatEther(totalPrice.mul(3000)));
  }

  return (
    <div className="HomePage">
      <Awning />
      <div className="foreground">
        <Sign />
        <hr/>
        <div className="container">
          
          
          <div>
            { !signer &&
              <div style={{textAlign:"center"}}>
                <p>Summer is forever! And it's a hot one.</p>
                <p>You look like you could use an onchain ice cream or two.</p>
                <p>Well don't stand out there like a stranger...</p>
                <button
                  className="primary"
                  onClick={connectWallet}
                >
                    Come on in!
                </button>
              </div>
            }
            { signer &&
              <div className="connected">
                <div className="welcome-line">
                  <div className="welcome-message">Welcome, Kiddo!</div>
                  <div className="address-info">
                    <div className="address">
                      <a href={`https://basescan.org/address/${signerAddress}`} target="_blank" rel="noreferrer">
                        {shortenAddress(signerAddress)}
                      </a>
                    </div>
                    <div
                      onClick={disconnectWallet}
                      className="disconnect-button"
                    >
                      <svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 4h3a2 2 0 0 1 2 2v1m-5 13h3a2 2 0 0 0 2-2v-1M4.425 19.428l6 1.8A2 2 0 0 0 13 19.312V4.688a2 2 0 0 0-2.575-1.916l-6 1.8A2 2 0 0 0 3 6.488v11.024a2 2 0 0 0 1.425 1.916zM9.001 12H9m7 0h5m0 0-2-2m2 2-2 2"/></svg>
                    </div>
                  </div>
                </div>
                { currPage === MENU &&
                  <>
                    <p style={{textAlign:'left'}}>Our menu is up on the chalkboard. Pop writes it himself every morning!<br/><br/>When you're ready to order, scroll on down and tell me what you'd like.</p>
                    <div className="menu">
                      <div className="cost-header">Just 0.0006 ETH per scoop</div>
                      <div className="flavor-header">Today's flavors</div>
                      <div className="flavors">
                        { FLAVOR_METADATA.filter(f => f.id !== Flavors.SURPRISE_ME).map(f => {
                          return (
                            <div
                              className="flavor-item"
                              key={f.id}
                            >
                              {f.label}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div>
                      <button className="primary" onClick={() => setCurrPage(ORDERING)}>I'm ready to order</button>
                    </div>
                  </>
                }
                { currPage === ORDERING &&
                  <>
                    { minting &&
                      <div>I'm minting — umm — I mean making that for you right now. Hold tight.</div>
                    }
                    {!minting &&
                      <>
                        <div className="question">How many scoops would you like?</div>
                        <div className="buttons">
                          <button className={numScoops === 1 ? 'selected' : 'secondary'} onClick={() => selectNumScoops(1)}>1</button>
                          <button className={numScoops === 2 ? 'selected' : 'secondary'} onClick={() => selectNumScoops(2)}>2</button>
                          <button className={numScoops === 3 ? 'selected' : 'secondary'} onClick={() => selectNumScoops(3)}>3</button>
                        </div>
                        { numScoops > 0 &&
                          <>
                            <hr/>
                            <div className="question">
                              { numScoops === 1
                                ? 'What flavor would you like?'
                                : 'What flavor would you like for your first scoop?'
                              }
                            </div>
                            <FlavorSelector
                              scoop={scoops[0]}
                              onChange={flavor => selectFlavor(flavor, 0)}
                            />
                          </>
                        }
                        { numScoops > 1 && scoops.length > 0 &&
                          <>
                            <div className="question">And how about your second scoop?</div>
                            <FlavorSelector
                              scoop={scoops[1]}
                              onChange={flavor => selectFlavor(flavor, 1)}
                            />
                          </>
                        }
                        { numScoops > 2 && scoops.length > 1 &&
                          <>
                            <div className="question">And the third one?</div>
                            <FlavorSelector
                              scoop={scoops[2]}
                              onChange={flavor => selectFlavor(flavor, 2)}
                            />
                          </>
                        }
                        { numScoops === scoops.length &&
                          <>
                            <hr/>
                            <div className="question">How many of those do you want? I'll make up to 20 per order.</div>
                            <div className="quantity-selector">
                              <input 
                                type="number"
                                value={quantity}
                                onChange={handleQuantitySelectorChange}
                                min="1"
                                max="20"
                              />
                            </div>
                            {/* (~${usd.toFixed(2)}) */}
                            <div className="mint-button-container">
                              Total price: {totalPrice !== null ? ethers.utils.formatEther(totalPrice) : 0} ETH 
                              <button className="mint-button primary" onClick={mint}>Purchase</button>
                            </div>
                          </>
                        }
                      </>
                    }
                  </>
                }
                { currPage === IMAGE &&
                  <>
                    {!svg &&
                      <div>Minted! Loading your ice cream from the chain...</div>
                    }
                    {svg &&
                      <>
                        {numMinted === 1 &&
                          <div>Here you go! Onchain Ice Cream #{tokenId} 😊</div>
                        }
                        {numMinted > 1 &&
                          <div>Here you go! Onchain Ice Cream #{tokenId}. The rest are in your wallet. 😊</div>
                        }
                        <div
                          className="ice-cream"
                          dangerouslySetInnerHTML={{ __html: svg }}
                        />
                        <div>
                          <button
                            className="primary"
                            onClick={() => setCurrPage(ORDERING)}
                          >
                              Place another order
                          </button>
                        </div>
                        
                      </>
                    }
                  </>
                }
              </div>
            }
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
