import React from 'react';

const Awning = () => {
  return (
    <div
      className="Awning"
      style={{
        width: '100%',
        height: '75px',
        backgroundImage: "url('/awning.svg')",
        backgroundRepeat: 'repeat-x',
        backgroundSize: '300px auto',
        backgroundPosition: 'center 0',
      }}
    >
    </div>
  )
};

export default Awning;